import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";

const LoginDialog = ({ open }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect().catch((e) => {
      console.error(e);
    });
  };

  return (
    <Dialog open={open} aria-labelledby="login-dialog-title">
      <DialogTitle id="login-dialog-title">Sign In Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please sign in to access the application.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogin} variant="contained">
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
