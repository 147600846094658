import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Link as RouterLink } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import { AuthenticatedTemplate } from "@azure/msal-react";

const NavigationSidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer(true)}
      >
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1.5px solid rgba(0, 0, 0, 0.20)",
            borderRadius: "15%",
            padding: "5px",
          }}
        >
          <MenuRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.53)" }} />
        </Box>
      </IconButton>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
            borderRadius: "0px 10px 10px 0px",
          },
        }}
      >
        <List>
          <AuthenticatedTemplate>
            {[
              { icon: <HomeIcon />, text: "Home", link: "/" },
              {
                icon: <EventNoteIcon />,
                text: "Appointments",
                link: "/appointments",
              },
              {
                icon: <GroupsIcon />,
                text: "People",
                link: "/people",
              },
            ].map((item, index) => (
              <ListItemButton
                key={index}
                component={RouterLink}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </AuthenticatedTemplate>
        </List>
      </Drawer>
    </>
  );
};

export default NavigationSidebar;
