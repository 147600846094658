import { useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import { useSnackbar } from "../context/SnackbarContext";

export const useUserProfile = () => {
  const { getToken } = useAuth();
  const { openSnackbar } = useSnackbar();

  const fetchUserProfile = useCallback(async () => {
    const accessToken = await getToken();
    if (!accessToken) {
      openSnackbar("Access token is null or empty.", "warning");
      return null;
    }

    try {
      const response = await fetch("/api/GetMyProfile", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      
      if (!response.ok) throw new Error("Failed to fetch profile.");

      const profileData = await response.json();
      return profileData;
    } catch (error) {
      openSnackbar("Error fetching profile.", "error");
      console.error("Error fetching profile:", error);
      return null;
    }
  }, [getToken, openSnackbar]);

  const updateProfile = useCallback(
    async (updateData) => {
      const accessToken = await getToken();
      if (!accessToken) {
        openSnackbar("Access token is null or empty.", "warning");
        return false;
      }

      try {
        const response = await fetch("/api/EditMyProfile", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(updateData),
        });

        if (!response.ok) throw new Error("Failed to update profile.");
        openSnackbar("Profile updated successfully.", "success");
        return true;
      } catch (error) {
        openSnackbar("Error updating profile.", "error");
        console.error("Error updating profile:", error);
        return false;
      }
    },
    [getToken, openSnackbar]
  );

  return { fetchUserProfile, updateProfile };
};
