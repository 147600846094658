import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";

function EditPersonDialog({ open, handleClose, person, handleEditPerson }) {
  const [updatedPerson, setUpdatedPerson] = useState(person);
  const [error, setError] = useState({ name: false });

  useEffect(() => {
    setUpdatedPerson(person);
    setError({ name: false });
  }, [person, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdatedPerson((prev) => ({ ...prev, [name]: value }));
    if (error[name]) {
      setError({ ...error, [name]: false });
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    if (!updatedPerson.name) {
      setError((prev) => ({ ...prev, name: true }));
      formIsValid = false;
    }
    return formIsValid;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      handleEditPerson(updatedPerson);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-person-dialog-title"
    >
      <DialogTitle id="edit-person-dialog-title">Edit Person</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={updatedPerson.name || ""}
            onChange={handleChange}
            error={error.name}
            helperText={error.name ? "Name is required" : ""}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="role"
            label="Role"
            name="role"
            autoComplete="role"
            value={updatedPerson.role || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="startDate"
            label="Start Date"
            name="startDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={updatedPerson.startDate || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="endDate"
            label="End Date"
            name="endDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={updatedPerson.endDate || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="contractType"
            label="Contract Type"
            name="contractType"
            autoComplete="contract-type"
            value={updatedPerson.contractType || ""}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleFormSubmit} variant="contained">
          Edit Person
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPersonDialog;
