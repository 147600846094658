import React, { createContext, useContext, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { instance } = useMsal();

  const getToken = useCallback(
    async (scopes = ["User.Read"]) => {
      let account = instance.getActiveAccount();
      if (!account) {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
          account = accounts[0];
          instance.setActiveAccount(account);
        } else {
          throw Error("No account available. User needs to sign in.");
        }
      }

      try {
        const response = await instance.acquireTokenSilent({
          scopes: scopes,
          account: account,
        });
        return response.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.acquireTokenPopup({
              scopes: scopes,
              account: account,
            });
            return response.accessToken;
          } catch (innerError) {
            console.error(
              "Error acquiring access token through interactive method",
              innerError
            );
            throw innerError;
          }
        } else {
          console.error("Error acquiring access token", error);
          throw error;
        }
      }
    },
    [instance]
  );

  const loginUser = useCallback(
    async (scopes = ["User.Read"]) => {
      try {
        const response = await getToken(scopes);
        return response;
      } catch (error) {
        console.error("Error during login process", error);
      }
    },
    [getToken]
  );

  const logoutUser = useCallback(() => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
  }, [instance]);

  return (
    <AuthContext.Provider value={{ getToken, loginUser, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
