import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useSnackbar } from "../context/SnackbarContext";

const useProfilePicture = (initialPicture) => {
  const [profilePicture, setProfilePicture] = useState(initialPicture);
  const { getToken } = useAuth();
  const { openSnackbar } = useSnackbar();

  const uploadProfilePicture = async (file) => {
    const accessToken = await getToken();

    if (!accessToken) {
      console.error("Access token is null or empty.");
      openSnackbar("Access token is null or empty.", "warning");
      return;
    }

    const url = "https://graph.microsoft.com/v1.0/me/photo/$value";

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
          Authorization: `Bearer ${accessToken}`,
        },
        body: file,
      });

      if (!response.ok) {
        openSnackbar("Failed to update profile picture.", "warning");
        throw new Error("Failed to upload profile picture.");
      }

      openSnackbar("Profile picture updated successfully.", "success");
    } catch (error) {
      openSnackbar("Error uploading profile picture.", "error");
      console.error("Error uploading profile picture:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(URL.createObjectURL(file));
      uploadProfilePicture(file);
    }
  };

  return {
    profilePicture,
    setProfilePicture,
    handleFileChange,
  };
};

export default useProfilePicture;
