import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function PeopleTable({
  people,
  isLoading,
  handleOpenDeletePersonDialog,
  handleOpenEditPersonDialog,
  handleOpenAddPersonDialog,
}) {
  const columns = [
    { field: "name", headerName: "Name", width: 170, editable: false },
    { field: "role", headerName: "Role", width: 130, editable: false },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 170,
      editable: false,
    },
    { field: "endDate", headerName: "End Date", width: 170, editable: false },
    {
      field: "contractType",
      headerName: "Contract Type",
      width: 170,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      editable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <React.Fragment>
            <Tooltip title="Edit person">
              <IconButton
                onClick={() => handleOpenEditPersonDialog(cellValues.row)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete person">
              <IconButton
                onClick={() => handleOpenDeletePersonDialog(cellValues.row)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: "55vh", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <Tooltip title="Add person">
          <IconButton
            onClick={handleOpenAddPersonDialog}
            color="primary"
            sx={{ mr: 4 }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <DataGrid
        rows={people}
        columns={columns}
        loading={isLoading}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}

export default PeopleTable;
