const baseUrl = "/api";

export const PeopleService = {
  getPeople: () => {
    return fetch(`${baseUrl}/GetPeople`).then((response) => {
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      return response.json();
    });
  },

  addPerson: (person) => {
    return fetch(`${baseUrl}/AddPerson`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(person),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to add the person.");
      }
      return response.json();
    });
  },

  editPerson: (person) => {
    return fetch(`${baseUrl}/EditPerson`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(person),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to update the person.");
      }
      return response.json();
    });
  },

  deletePerson: (id) => {
    return fetch(`${baseUrl}/DeletePerson?id=${id}`, {
      method: "DELETE",
    }).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete the person.");
      }
    });
  },
};
