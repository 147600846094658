import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import LoginDialog from "./LoginDialog";

function AuthRoute({ children }) {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoginDialog open={true} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
}

export default AuthRoute;
