import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";

function AddPersonDialog({
  open,
  handleClose,
  newPerson,
  handleChange,
  handleAddPerson,
}) {
  const [error, setError] = useState({ name: false });

  const validateForm = () => {
    if (!newPerson.name) {
      setError({ ...error, name: true });
      return false;
    }
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      handleAddPerson(event);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-person-dialog-title"
    >
      <DialogTitle id="add-person-dialog-title">Add Person</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={newPerson.name}
            onChange={handleChange}
            error={error.name}
            helperText={error.name ? "Name is required" : ""}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="role"
            label="Role"
            name="role"
            autoComplete="role"
            value={newPerson.role}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="startDate"
            label="Start Date"
            name="startDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newPerson.startDate}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="endDate"
            label="End Date"
            name="endDate"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newPerson.endDate}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="contractType"
            label="Contract Type"
            name="contractType"
            autoComplete="contract-type"
            value={newPerson.contractType}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleFormSubmit}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Add Person
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPersonDialog;
