import React, { useState, useEffect } from "react";
import {
  Avatar,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import { Link as RouterLink } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { getUserInitials } from "../utils/helpers";
import { useAuth } from "../context/AuthContext";

const UserProfileSidebar = () => {
  const { user } = useUser();
  const { logoutUser } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [initials, setInitials] = useState(
    getUserInitials(user?.givenName, user?.surname)
  );

  useEffect(() => {
    setInitials(getUserInitials(user?.givenName, user?.surname));
  }, [user?.givenName, user?.surname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <IconButton edge="start" onClick={toggleDrawer(true)}>
        <Avatar sx={{ bgcolor: "#1976d2", color: "#fff" }}>{initials}</Avatar>
      </IconButton>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
            borderRadius: "10px 0px 0px 10px",
          },
        }}
      >
        <List>
          <ListItemButton
            component={RouterLink}
            to="/profile"
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <Divider sx={{ width: "90%", margin: "0 auto" }} />
          <ListItemButton onClick={logoutUser}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default UserProfileSidebar;
