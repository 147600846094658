import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Adjust the import path as necessary

const LogoutHandler = () => {
  const { logoutUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logoutUser();
  }, [logoutUser, navigate]);

  return null; // Renders nothing
};

export default LogoutHandler;
