import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuth } from "./AuthContext";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../utils/MsGraphApiCall";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { getToken } = useAuth();
  const { accounts } = useMsal();

  const fetchUserProfile = useCallback(async () => {
    try {
      const accessToken = await getToken();
      const userProfile = await callMsGraph(accessToken);
      if (userProfile) {
        setUser(userProfile);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  }, [getToken]);

  useEffect(() => {
    if (accounts.length > 0) {
      fetchUserProfile().catch(console.error);
    }
  }, [accounts, fetchUserProfile]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
