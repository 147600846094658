import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import { useUser } from "../context/UserContext";
import { getUserInitials } from "../utils/helpers";
import useProfilePicture from "../hooks/useProfilePicture";
import { useUserProfile } from "../hooks/useUserProfile";

const Profile = () => {
  const { user } = useUser();
  const { fetchUserProfile, updateProfile } = useUserProfile();
  const [displayName, setDisplayName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [surname, setSurname] = useState("");
  const [initials, setInitials] = useState(
    getUserInitials(user?.givenName, user?.surname)
  );
  const fileInputRef = useRef(null);

  const { profilePicture, handleFileChange } = useProfilePicture(
    user?.profilePicture
  );

  useEffect(() => {
    const loadProfile = async () => {
      const profileData = await fetchUserProfile();
      if (profileData) {
        setDisplayName(profileData.displayName || "");
        setGivenName(profileData.givenName || "");
        setSurname(profileData.surname || "");
        setInitials(
          getUserInitials(profileData.givenName, profileData.surname)
        );
      }
    };
    loadProfile();
  }, [fetchUserProfile]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await updateProfile({ displayName, givenName, surname });
    if (success) fetchUserProfile(); // Optionally re-fetch profile data on successful update
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "2rem",
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Profile
      </Typography>
      <Divider sx={{ width: "100%", maxWidth: "400px", margin: "0 auto" }} />
      <Tooltip title="Change your avatar" placement="bottom">
        <Box textAlign="center">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="profile-picture-input"
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <IconButton edge="start" component="span" onClick={handleAvatarClick}>
            <Avatar
              src={profilePicture}
              alt="Profile"
              sx={{
                bgcolor: "#1976d2",
                color: "#fff",
                width: 192,
                height: 192,
                fontSize: "6rem",
                cursor: "pointer",
              }}
            >
              {!profilePicture && initials}
            </Avatar>
          </IconButton>
        </Box>
      </Tooltip>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <TextField
          label="Display name"
          variant="outlined"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Given name"
          variant="outlined"
          value={givenName}
          onChange={(e) => setGivenName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Surname"
          variant="outlined"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Save Changes
        </Button>
      </form>
    </Box>
  );
};

export default Profile;
