import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import NotFound from "./components/NotFound";
import AuthRoute from "./components/AuthRoute";
import Home from "./components/Home";
import Appointments from "./components/Appointments";
import PeopleList from "./components/People/PeopleList";
import { SnackbarProvider } from "./context/SnackbarContext";
import Profile from "./components/Profile";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { AuthProvider } from "./context/AuthContext";
import LogoutHandler from "./components/LogoutHandler";

function App({ pca }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <AuthProvider>
        <UserProvider>
          <SnackbarProvider>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
              }}
            >
              <Navbar />
              <Container component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <AuthRoute>
                        <Home />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/appointments"
                    element={
                      <AuthRoute>
                        <Appointments />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/people"
                    element={
                      <AuthRoute>
                        <PeopleList />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <AuthRoute>
                        <Profile />
                      </AuthRoute>
                    }
                  />
                  <Route path="/logout" element={<LogoutHandler />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Container>
              <Footer />
            </Box>
          </SnackbarProvider>
        </UserProvider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;
