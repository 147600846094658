import React from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";
import { AuthenticatedTemplate } from "@azure/msal-react";
import UserProfileSidebar from "./UserProfileSidebar";
import NavigationSidebar from "./NavigationSidebar";
import { useLocation, Link } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <AppBar position="static" sx={{ bgcolor: "#f5f5f5" }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <AuthenticatedTemplate>
            <NavigationSidebar />
          </AuthenticatedTemplate>
          <IconButton
            component={Link}
            to="/"
            edge="start"
            color="inherit"
            aria-label="logo"
            sx={{ ml: 1, mr: 1 }}
          >
            <img
              src="./android-chrome-192x192.png"
              alt="Logo"
              style={{ height: "50px" }}
            />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {location.pathname}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <AuthenticatedTemplate>
              <UserProfileSidebar />
            </AuthenticatedTemplate>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
