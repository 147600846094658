import React, { useState, useEffect } from "react";
import AddPersonDialog from "./AddPersonDialog";
import PeopleTable from "./PeopleTable";
import DeletePersonDialog from "./DeletePersonDialog";
import EditPersonDialog from "./EditPersonDialog";
import { PeopleService } from "../PeopleService";
import { useSnackbar } from "../../context/SnackbarContext";

function PeopleList() {
  const [people, setPeople] = useState([]);
  const [newPerson, setNewPerson] = useState({
    name: "",
    role: "",
    startDate: "",
    endDate: "",
    contractType: "",
  });
  const [openAddPersonDialog, setOpenAddPersonDialog] = useState(false);
  const [openEditPersonDialog, setOpenEditPersonDialog] = useState(false);
  const [openDeletePersonDialog, setOpenDeletePersonDialog] = useState(false);
  const [personToEdit, setPersonToEdit] = useState(null);
  const [personToDelete, setPersonToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    setIsLoading(true);
    PeopleService.getPeople()
      .then((data) => {
        setPeople(data);
        setIsLoading(false);
        openSnackbar("People loaded successfully", "success");
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsLoading(false);
        openSnackbar("Failed to load data", "error");
      });
  }, [openSnackbar]);

  const handleAddPerson = (event) => {
    event.preventDefault();
    PeopleService.addPerson(newPerson)
      .then((data) => {
        setPeople([data, ...people]);
        setNewPerson({
          name: "",
          role: "",
          startDate: "",
          endDate: "",
          contractType: "",
        });
        handleCloseAddDialog();
        openSnackbar("Person added successfully!", "success");
      })
      .catch((error) => {
        console.error("There was an error adding the person!", error);
        openSnackbar("Failed to add person.", "error");
      });
  };

  const handleEditPerson = (updatedPerson) => {
    PeopleService.editPerson(updatedPerson)
      .then((data) => {
        setPeople(
          people.map((person) => (person.id === data.id ? data : person))
        );
        handleCloseEditDialog();
        openSnackbar("Person updated successfully!", "success");
      })
      .catch((error) => {
        console.error("There was an error updating the person!", error);
        openSnackbar("Failed to update person.", "error");
      });
  };

  const handleDeletePerson = () => {
    PeopleService.deletePerson(personToDelete.id)
      .then(() => {
        setPeople(people.filter((person) => person.id !== personToDelete.id));
        setPersonToDelete(null);
        handleCloseDeleteDialog();
        openSnackbar("Person deleted successfully!", "success");
      })
      .catch((error) => {
        console.error("There was an error deleting the person!", error);
        openSnackbar("Failed to delete person.", "error");
      });
  };

  const handleOpenAddPersonDialog = () => setOpenAddPersonDialog(true);
  const handleCloseAddDialog = () => setOpenAddPersonDialog(false);

  const handleOpenEditPersonDialog = (person) => {
    setPersonToEdit(person);
    setOpenEditPersonDialog(true);
  };

  const handleCloseEditDialog = () => {
    setPersonToEdit(null);
    setOpenEditPersonDialog(false);
  };

  const handleOpenDeletePersonDialog = (person) => {
    setPersonToDelete(person);
    setOpenDeletePersonDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeletePersonDialog(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPerson((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <AddPersonDialog
        open={openAddPersonDialog}
        handleClose={handleCloseAddDialog}
        newPerson={newPerson}
        handleChange={handleChange}
        handleAddPerson={handleAddPerson}
      />
      <PeopleTable
        people={people}
        isLoading={isLoading}
        handleOpenDeletePersonDialog={handleOpenDeletePersonDialog}
        handleOpenEditPersonDialog={handleOpenEditPersonDialog}
        handleOpenAddPersonDialog={handleOpenAddPersonDialog}
      />
      {personToEdit && (
        <EditPersonDialog
          open={openEditPersonDialog}
          handleClose={handleCloseEditDialog}
          person={personToEdit}
          handleEditPerson={handleEditPerson}
        />
      )}
      {personToDelete && (
        <DeletePersonDialog
          open={openDeletePersonDialog}
          onClose={handleCloseDeleteDialog}
          onConfirmDeletePerson={handleDeletePerson}
          personName={personToDelete.name}
        />
      )}
    </>
  );
}

export default PeopleList;
