import React from "react";
import {
  Container,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const images = [
  "/NotFound1.webp",
  "/NotFound2.webp",
  "/NotFound3.webp",
  "/NotFound4.webp",
];

const NotFound = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const randomImagePath = images[Math.floor(Math.random() * images.length)];

  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", marginTop: "8.4px", marginBottom: "20px" }}
    >
      <Typography variant={isDesktop ? "h4" : "h5"} gutterBottom>
        404 - Page Not Found
      </Typography>
      <img
        src={randomImagePath}
        alt="Not Found"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <Typography variant="subtitle1" gutterBottom>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Homepage
      </Button>
    </Container>
  );
};

export default NotFound;
